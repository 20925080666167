import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import content from "../../data/content.json"
import Button from "./button"
import ButtonPhone from "./button-phone"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ openModal }) => {
  const query = useStaticQuery(
    graphql`
      {
        company: airtable(table: { eq: "Basics" }) {
          data {
            Brand
            Description
            Phone
            Opening_hours
            Palette
            Logo {
              localFiles {
                childImageSharp {
                  gatsbyImageData(sizes: "", width: 130, placeholder: NONE)
                }
              }
            }
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    sizes: ""
                    layout: FULL_WIDTH
                    aspectRatio: 1
                    placeholder: NONE
                    height: 60
                  )
                }
              }
            }
          }
        }
        site: airtable(table: { eq: "Sites" }) {
          data {
            Header
            Subtitle
            Cover {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    sizes: ""
                    layout: FULL_WIDTH
                    placeholder: NONE
                  )
                }
              }
            }
            Header_type
            Header_background
            Logo_type
            Actions
          }
        }
      }
    `
  )

  const coverImage = [
    
    query.site.data.Cover.localFiles[0].childImageSharp.gatsbyImageData,
    `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))`].reverse()

  const color = query.company.data.Palette

  const colorMap = {
    Indigo: {
      dark: "bg-indigo-900 text-indigo-100",
      light: "bg-indigo-100 text-indigo-900",
    },
    Blue: {
      dark: "bg-blue-900 text-blue-100",
      light: "bg-blue-100 text-blue-900",
    },
    Lime: {
      dark: "bg-lime-900 text-lime-100",
      light: "bg-lime-100 text-lime-900",
    },
    Yellow: {
      dark: "bg-yellow-600 text-yellow-100",
      light: "bg-yellow-100 text-yellow-600",
    },
    Orange: {
      dark: "bg-yellow-900 text-yellow-100",
      light: "bg-yellow-100 text-yellow-900",
    },
    Pink: {
      dark: "bg-pink-900 text-pink-100",
      light: "bg-pink-100 text-pink-900",
    },
    Purple: {
      dark: "bg-purple-900 text-purple-100",
      light: "bg-purple-100 text-purple-900",
    },
    Red: {
      dark: "bg-red-900 text-red-100",
      light: "bg-red-100 text-red-900",
    },
    Green: {
      dark: "bg-green-900 text-green-100",
      light: "bg-gradient-to-b from-green-100 text-green-900",
    },
    Black: {
      dark: "bg-black text-white",
      light: "bg-gray-100",
    },
  }

  const featuredImage =
    query.site.data.Cover.localFiles[0].childImageSharp.gatsbyImageData

  const headerType = query.site.data.Header_type

  const imageFrame = () => {
    switch (headerType) {
      case "Rounded image":
        return {
          borderRadius: "50%",
          borderWidth: "8px",
          borderColor: "white",
        }
      default:
        return ""
    }
  }

  const HeaderBg = query.site.data.Header_background

  const brandIcon =
    query.company.data.Icon.localFiles[0].childImageSharp.gatsbyImageData
  const brandLogo =
    query.company.data.Logo.localFiles[0].childImageSharp.gatsbyImageData

  const logoType =
    query.site.data.Logo_type === "Icon + Brand name" ? (
      <div className="flex flex-row items-start space-x-2">
        <div className="h-10 w-10">
          <GatsbyImage
            image={brandIcon}
            imgStyle={{
              borderRadius: "50%",
              borderWidth: "2px",
              borderColor: "white",
            }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={query.company.data.Brand}
          ></GatsbyImage>
        </div>
        <h1 className="text-2xl text-left">
          <Link to="/">{query.company.data.Brand}</Link>
        </h1>
      </div>
    ) : query.site.data.Logo_type === "Logo" ? (
      <GatsbyImage
        image={brandLogo}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt={query.company.data.Brand}
      ></GatsbyImage>
    ) : query.site.data.Logo_type === "Icon" ? (
      <div className="h-20 w-20">
      <GatsbyImage
        image={brandIcon}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt={query.company.data.Brand}
      ></GatsbyImage>
      </div>
    ) :
     (
      <h1 className="text-2xl text-left">
        <Link to="/">{query.company.data.Brand}</Link>
      </h1>
    )

  const hasPhoneButton = query.site.data.Actions.includes("📞 Call")

  return (
    <header>
      {headerType === "Cover" ? (
        <div
          style={{
            display: `grid`,
            alignItems: "left",
          }}
        >
          <BgImage
            Tag="section"
            image={coverImage}
            style={{
              gridArea: "1/1"
            }}
            layout="fullWidth"
            aspectRatio={3 / 1}
          >
<div
            style={{
              gridArea: "1/1",
              position: "relative",
              placeItems: "left",
              display: "grid",
            }}
            className="container-default py-4 lg:py-10 text-center text-white flex flex-col space-y-40"
          >
            <div className="flex flex-row items-start space-x-2">
              {logoType}
            </div>
            <div className="flex flex-col items-center justify-between lg:space-x-28 pb-7">
              <div className="w-full lg:w-2/3 space-y-6 lg:space-y-10">
                <h1 className="filter drop-shadow-lg">
                  {query.site.data.Header}
                </h1>
                <p className="text-xl sm:text-2xl font-bold max-w-prose text-white filter drop-shadow-lg leading-normal">
                  {query.site.data.Subtitle}
                </p>
                <div className="flex flex-col justify-center md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                  <Button onClick={openModal} content={content}>
                    {content.action.form.text}
                  </Button>
                  {hasPhoneButton && <ButtonPhone />}
                </div>
              </div>
            </div>
          </div>

          </BgImage>
          
        </div>
      ) : (
        <div
          className={`${
            HeaderBg === "Dark" ? colorMap[color].dark : colorMap[color].light
          }`}
        >
          <div className="container-default pt-7">
            <div>{logoType}</div>
            <div className="flex flex-col lg:flex-row items-center justify-between lg:space-x-28 pb-7 pt-16 md:pt-8 lg:pt-0">
              <div className="w-full lg:w-1/2 space-y-8 pb-7 lg:pb-0">
                <h1>{query.site.data.Header}</h1>
                <p className="text-xl text-current">
                  {query.site.data.Subtitle}
                </p>
                <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0 lg:flex-col lg:space-x-0 lg:space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0 max-w-xl">
                  <Button onClick={openModal} content={content}>
                    {content.action.form.text}
                  </Button>
                  {hasPhoneButton && <ButtonPhone />}
                </div>
              </div>
              <div className="w-full md:w-2/3 lg:w-1/2 justify-center">
                <GatsbyImage
                  image={featuredImage}
                  imgStyle={imageFrame(headerType)}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt={query.company.data.Brand}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
