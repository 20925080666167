import * as React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/react"
import Header from "./header"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"

const Layout = ({ children, handleShow, openModal, business, className }) => {

  const query = useStaticQuery(
    graphql`
      query {
        company: airtable(table: { eq: "Basics" }) {
            data {
              Brand
              Style
              Palette
            }
          }
      }
      `
  )
  
  const theme = {
    style: query.company.data.Style,
    palette: query.company.data.Palette
  }

  const style = theme.style

  const Typography = () => {

    return style === "Smart"
      ? `
     h1, h2, h3, h4 {
       font-family: Poppins Bold;
       font-display: swap;
     };
     p, button {
      font-family: Poppins Regular;
      font-display: swap;
    }
    button {
      background-color: black;
    }
   `
      : style === "Playful"
      ? `
   h1, h2, h3, h4 {
     font-family: Mulish Bold;
     font-display: swap;
   };
   p, button {
    font-family: Mulish Regular;
    font-display: swap;
  }
 `
      : style === "Elegant"
      ? `
h1, h2, h3, h4 {
font-family: PTSerif Bold;
font-display: swap;
};
p, button {
font-family: PTSerif Regular;
font-display: swap;
}
`
      : style === "Hacker"
      ? `
h1, h2, h3, h4 {
font-family: IBMPlexMono Bold;
font-display: swap;
};
button {
  background-color: #A3E635
}
p {
font-family: sans-serif;
font-display: swap;
}
`
      : style === "Bold"
      ? `
h1, h2, h3, h4 {
font-family: Arvo Bold;
font-display: swap;
};
p, button {
font-family: Arvo Regular;
font-display: swap;
}
`
      : style === "Simple"
      ? `
h1, h2, h3, h4 {
font-family: Nunito Bold;
font-display: swap;
};
p, button {
font-family: Nunito Regular;
font-display: swap;
}
`
      : style === "Modern"
      ? `
h1, h2, h3, h4 {
font-family: Inter Bold;
font-display: swap;
};
p, button {
font-family: Inter Regular;
font-display: swap;
}
`
      : ""
  }

  return (
    <>
      <Global
        styles={css`
          ${Typography()}
        `}
      />
      <div className={`mx-auto ${className}`}>
        <Header handleShow={handleShow} openModal={openModal} />
        <main>{children}</main>
        <footer className="container-default flex sm:flex-row py-10 justify-between">
          <p className="">{`© ${business}`}</p>
          <p>
            Made with{" "}
            <a
              className="font-bold"
              href="https://www.tryevergrow.com"
              target="blank"
            >
              Evergrow
            </a>
          </p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
